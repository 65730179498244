<!-- 
 * @description: 
 * @fileName: download.vue 
 * @author:ninghf 
 * @date: 2022-11-04 11:30:27
!-->
<template>
    <div class="download-container">
        <h1>在线考试系统 <span>PC</span>下载</h1>
        <div class="download-client">
            <div class="download-title">
                <div class="title-left title-module">
                    <a :href="clientUrlWindows">
                        <svg-icon icon-class="down-shape"></svg-icon>
                        <span>Windows版下载</span>
                    </a>
                </div>
                <div class="title-right title-module">
                    <a :href="clientUrlMac">
                        <svg-icon icon-class="down-mac"></svg-icon>
                        <span>Mac版下载</span>
                    </a>
                </div>
            </div>
            <div class="download-content">
                <div class="download-content-left download-content-module">
                    <p class="title">
                        <svg-icon icon-class="down-install"></svg-icon>
                        <span>安装教程</span>
                    </p>
                    <div class="content">
                        <p>点击下载按钮，完成客户端下载</p>
                        <p>下载完毕，双击客户端图标，即可运行</p>
                    </div>
                </div>
                <div class="download-content-right download-content-module">
                    <p class="title">
                        <svg-icon icon-class="down-set"></svg-icon>
                        <span>系统要求</span>
                    </p>
                    <div class="content">
                        <p>Windows版：Windows7 SP1及以上版本</p>
                        <p>Mac版：适用于mac OS X 10.11及以上系统上</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'DownloadClient',
    data(){
        return{
            envs:process.env.NODE_ENV == 'production' ? 'pro' : 'test',
            clientUrlMac:'',
            clientUrlWindows:'',
        }
    },
    created(){
        const configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
        let clientUrlWindows = configurationArr && configurationArr.find((item) => item.key === 'win_client_download_url')
        this.clientUrlWindows = clientUrlWindows?.value;
        let clientUrlMac = configurationArr && configurationArr.find((item) => item.key === 'mac_client_download_url')
        this.clientUrlMac = clientUrlMac?.value;
        console.log(this.clientUrlMac,'11111111111111111111111');
    }
}
</script>
<style lang="stylus" scoped>
.download-container{
    display flex;
    flex-direction column;
    height:100vh;
    justify-content center;
    background #fff;
}
h1{
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0D254B;
    line-height: 50px;
    text-align center;
    margin-bottom 80px;
    span{
        color:#1370FF;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
    }
}
.download-title{
    display flex;
    align-items center;
    justify-content space-between;
    width:768px;
    margin:0 auto 56px;
    .title-module{
        text-align center;
        background: #3760FF;
        box-shadow: 0px 2px 8px 0px rgba(55,96,255,0.5);
        border-radius: 4px;
        line-height 80px;
        transition all .5s;
        a{
            display flex;
            align-items center;
            justify-content center;
            width: 344px;
            height: 80px;
            color:#fff;
        }
        &:hover{
            cursor pointer;
            transform scale(1.1);
        }
        .icon{
            font-size 40px;
        }
        span{
            font-weight 600;
            font-size 22px;
            margin-left 12px;
        }
    }
}
.download-content{
    width:768px;
    margin:0 auto;
    display flex;
    border: 1px solid #D2D5D9;
    border-radius:4px;
    position relative
    align-items center;
    &::after{
        background:#D2D5D9;
        width:1px;
        height:102px;
        position absolute;
        top:50%;
        left:50%;
        transform translate(-50%,-50%);
        content:'';
        
    }
    .download-content-module{
        flex:1;
        padding-top:46px;
        padding-bottom:50px;
        .title{
            margin-bottom 16px;
            .icon{
                font-size 18px;
            }
            span{
                margin-left 4px;
                color: #0D254B;
                font-weight:600;
                font-size:16px;
                font-family: PingFangSC-Semibold, PingFang SC;
            }
        }
        .content p{
            font-family: PingFangSC-Regular, PingFang SC;
            font-size 14px;
            color:#5A6B84;
            padding-left 22px;
            line-height 20px;
            &:nth-child(1){
                margin-bottom:6px;
            }
        }
    }
    .download-content-left{
        padding-left 60px;
    }
    .download-content-right{
        padding-left 32px;
    }
}
</style>

